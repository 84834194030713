import { IFrontLine, IFrontLineDto } from '@shared/interfaces/frontline.interface';
import { FrontLineLangModel } from '@shared/models/frontline-lang.model';
import { GenericModel } from '@shared/models/generic.model';

export class FrontLineModel extends GenericModel<IFrontLine, IFrontLineDto> implements IFrontLine {
    code: string;
    area_code: string;
    units: string;
    name: string;
    translation: string;
    dateformat: string;
    companycode: string;
    country: string;
    langs: FrontLineLangModel[];

    constructor(data?: Partial<IFrontLineDto>) {
        super(data, FrontLineModel);

        this.code = data?.code ?? '';
        this.area_code = data?.area_code ?? '';
        this.units = data?.units ?? '';
        this.name = data?.name ?? '';
        this.translation = data?.translation ?? '';
        this.dateformat = data?.dateformat ?? '';
        this.companycode = data?.companycode ?? '';
        this.country = data?.country ?? '';
        this.langs = data?.langs?.map(l => new FrontLineLangModel(l)) ?? [];
    }

    dataTransferObject(): Partial<IFrontLineDto> {
        return {
            code: this.code,
            area_code: this.area_code,
            units: this.units,
            name: this.name,
            translation: this.translation,
            dateformat: this.dateformat,
        };
    }
}
