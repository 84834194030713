import { Location } from '@angular/common';
import { computed, inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { INavigationOptions } from '@shared/interfaces/navigation-options.interface';
import { TPageType } from '@shared/interfaces/route.interface';
import { AppRouteService } from '@shared/services/app-route.service';
import { delay, filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppNavigationService {
    private router = inject(Router);
    private location = inject(Location);
    private appRouteService = inject(AppRouteService);

    defaultRoutePath: WritableSignal<string> = signal(
        this.appRouteService.getPath(this.appRouteService.defaultRoute),
    );

    previousStateFrom: Signal<string> = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            map(() => {
                const navigation = this.router.getCurrentNavigation();
                return navigation?.extras.state ? navigation?.extras.state['from'] : '';
            }),
        ),
        { initialValue: '' },
    );

    previousRoute = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            map(() => this.router.url),
        ),
        { initialValue: '' },
    );

    currentRoute = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.router.url.split('?')[0]),
        ),
        { initialValue: '' },
    );

    queryParams = toSignal(
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            delay(200),
            map(() => this.router.parseUrl(this.router.url).queryParams),
        ),
        { initialValue: {} as Params },
    );

    navigationStart = toSignal(
        this.router.events.pipe(map(event => event instanceof NavigationStart)),
        { initialValue: false },
    );

    navigationEnd = toSignal(
        this.router.events.pipe(map(event => event instanceof NavigationEnd)),
        { initialValue: false },
    );

    hasBackButton = computed(
        () => !this.appRouteService.getMenuRoutes().some((r: string) => r === this.currentRoute()),
    );

    constructor() {
        window.addEventListener('popstate', () => {
            this.goBack(true);
        });
    }

    navigateTo(page: TPageType, options?: Partial<INavigationOptions>) {
        let commands: string[] = [this.appRouteService.getPath(page)];

        if (options?.parameter) commands = commands.concat(options?.parameter.split('/'));

        this.router.navigate(commands, {
            queryParams: options?.queryParams,
            state: {
                from: options?.from,
            },
            replaceUrl: options?.from == 'image' ? false : !!options?.from,
        });
    }

    goBack(skipGoBack?: boolean) {
        if (!skipGoBack) {
            if (window.history.length === 1) this.navigateTo('users');
            else this.location.back();
        }
    }

    getUrl(page: TPageType, options?: Partial<INavigationOptions>): string {
        let commands: string[] = [this.appRouteService.getPath(page)];
        if (options?.parameter) commands = commands.concat(options?.parameter.split('/'));
        return this.router
            .createUrlTree(commands, {
                queryParams: this.filteredQueryParams(options?.queryParams),
            })
            .toString();
    }

    updateUrl(page: TPageType, options?: Partial<INavigationOptions>): void {
        this.location.replaceState(this.getUrl(page, options));
    }

    private filteredQueryParams(queryParams: any) {
        return Object.fromEntries(
            Object.entries(queryParams || {}).filter(([_, value]) => {
                if (Array.isArray(value)) {
                    return value.length > 0 && value.some(v => v !== '');
                }
                return value !== '';
            }),
        );
    }
}
