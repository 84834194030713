import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppService } from '@shared/services/app.service';
import { SitesService } from '@shared/services/sites.service';
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';

export const AuthGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userData = inject(UserService);
    const appService: AppService = inject(AppService);
    const appNavigationService = inject(AppNavigationService);

    const sites = inject(SitesService).sites;

    return toObservable(appService.loading).pipe(
        filter(loading => !loading),
        map(loading => {
            if (userData.isLoggedIn()) {
                if (!sites().length) {
                    appNavigationService.navigateTo('auth', {
                        parameter: 'sites-unavailable',
                    });
                }
                return true;
            }

            appNavigationService.navigateTo('auth');
            return false;
        }),
    );
};
