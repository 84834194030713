import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import frontlinesData from '@assets/json/frontline.json';
import { environment } from '@env/environment';
import { IFrontLineDto } from '@shared/interfaces/frontline.interface';
import { IResponseItem } from '@shared/interfaces/response-item.interface';
import { IUserDto } from '@shared/interfaces/user.interface';
import { FrontLineModel } from '@shared/models/frontline.model';
import { UserModel } from '@shared/models/user.model';
import { tools } from '@utils/tools';
import { translations } from '@utils/translations';
import { catchError, delay, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const ENDPOINT = environment.api;

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private http: HttpClient = inject(HttpClient);

    getFrontLines(): Observable<FrontLineModel[]> {
        return of(frontlinesData.frontline).pipe(
            map((fronlines: any[]) => {
                return fronlines.map((f: IFrontLineDto) => new FrontLineModel(f));
            }),
            delay(100),
        );
    }

    getUserData(): Observable<IResponseItem<UserModel>> {
        // const errorResponse = {
        //    status: 403,
        //    message: 'Unauthorized'
        // };
        //
        // return throwError(errorResponse);
        return this.http.get<IUserDto>(ENDPOINT + '/me').pipe(
            map(user => ({
                success: true,
                value: new UserModel(user),
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(error, translations.auth.error),
                    },
                });
            }),
        );
    }

    patchUser(user: UserModel) {
        return this.http.patch<IUserDto>(ENDPOINT + '/me', user.dataTransferObject());
    }
}
