import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpService } from '@core/http/http.service';
import { TermsPrivacyComponent } from '@pages/auth/components/terms-privacy/terms-privacy.component';
import { CustomDialogService } from '@shared/components/custom-dialog/custom-dialog.service';
import { createDataStore } from '@shared/data-store';
import { IResponseItem } from '@shared/interfaces/response-item.interface';
import { UserModel } from '@shared/models/user.model';
import { TUser } from '@shared/types/user.type';
import { translations } from '@utils/translations';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private httpService = inject(HttpService);
    private customDialogService = inject(CustomDialogService);

    store = createDataStore<UserModel, 'email'>({
        primaryKey: 'email',
        initialItemValue: new UserModel(),
    });

    item = computed(() => this.store.item());
    user = computed(() => this.item().value as UserModel);
    globalAdmin = computed(() => this.user().role == 'globalAdmin');
    technical = computed(() => this.user().role == 'technical');
    facilityManager = computed(() => this.user().role == 'facilityManager');
    viewer = computed(() => this.user().role == 'viewer');
    isLoggedIn = computed(() => this.user().isLoggedIn);

    roles = signal<{ label: string; value: TUser }[]>([
        { label: translations.roles.globalAdmin, value: 'globalAdmin' },
        { label: translations.roles.technical, value: 'technical' },
        { label: translations.roles.facilityManager, value: 'facilityManager' },
        { label: translations.roles.viewer, value: 'viewer' },
    ]).asReadonly();

    setUserData(): Promise<IResponseItem<UserModel>> {
        return new Promise<IResponseItem<UserModel>>(resolve => {
            this.httpService.getUserData().subscribe({
                next: async data => {
                    if (data.value) {
                        this.store.updateItem(data.value);
                        if (!data.value.termsAndConditionsAccepted) {
                            const res = await this.openTermsPrivacy();
                            if (!res) {
                                resolve({});
                                return;
                            }
                        }
                    }

                    resolve(data);
                },
                error: () => {
                    resolve({});
                },
            });
        });
    }

    clearUser() {
        this.store.updateItem(new UserModel());
    }

    async openTermsPrivacy() {
        return await lastValueFrom(this.customDialogService.open(TermsPrivacyComponent).onClose);
    }
}
