import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppNavigationService } from '@shared/services/app-navigation.service';
import { AppRouteService } from '@shared/services/app-route.service';
import { AppService } from '@shared/services/app.service';
import { SitesService } from '@shared/services/sites.service';
import { UserService } from '@shared/services/user.service';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';

export const LoginGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userService = inject(UserService);
    const appService: AppService = inject(AppService);
    const appRouteService: AppRouteService = inject(AppRouteService);
    const appNavigationService = inject(AppNavigationService);

    const sites = inject(SitesService).sites;

    return toObservable(appService.loading).pipe(
        filter(loading => !loading),
        map(loading => {
            if (userService.isLoggedIn() && sites().length > 0) {
                appNavigationService.navigateTo(appRouteService.defaultRoute);
                return false;
            }

            return true;
        }),
    );
};
