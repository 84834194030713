import { IFrontLineLang, IFrontLineLangDto } from '@shared/interfaces/frontline-lang.interface';
import { GenericModel } from '@shared/models/generic.model';

export class FrontLineLangModel
    extends GenericModel<IFrontLineLang, IFrontLineLangDto>
    implements IFrontLineLang
{
    code: string;
    translation: string;

    constructor(data?: Partial<IFrontLineLangDto>) {
        super(data, FrontLineLangModel);

        this.code = data?.code ?? '';
        this.translation = data?.translation ?? '';
    }

    dataTransferObject(): Partial<IFrontLineLangDto> {
        return {
            code: this.code,
            translation: this.translation,
        };
    }
}
