{
    "frontline": [
        {
            "code": "CEB",
            "area_code": "CA",
            "units": "metric",
            "name": "Canada",
            "translation": "Canada",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "CHN",
            "area_code": "CN",
            "units": "metric",
            "name": "China",
            "translation": "China",
            "dateformat": "yyyy-MM-dd",
            "langs": [
                {
                    "code": "zh",
                    "translation": "Chinese"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "EGB",
            "area_code": "GB",
            "units": "metric",
            "name": "United Kingdom",
            "translation": "United Kingdom",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "EGG",
            "area_code": "DE",
            "units": "metric",
            "name": "Germany",
            "translation": "Germany",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "de",
                    "translation": "German"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "EGY",
            "area_code": "EG",
            "units": "metric",
            "name": "Egypt",
            "translation": "Egypt",
            "dateformat": "dd/mm/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "EMG",
            "area_code": "AE",
            "units": "metric",
            "name": "United Arab Emirates",
            "translation": "United Arab Emirates",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "GSL",
            "area_code": "GS",
            "units": "metric",
            "name": "Global Supply Line",
            "translation": "Global Supply Line",
            "dateformat": "dd/mm/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KAL",
            "area_code": "SA",
            "units": "metric",
            "name": "Saudi Arabia",
            "translation": "Saudi Arabia",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KBH",
            "units": "metric",
            "name": "Bahrain",
            "translation": "Bahrain",
            "dateformat": "dd/MM/yyyy",
            "area_code": "BH",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KCY",
            "units": "metric",
            "name": "Cyprus",
            "translation": "Cyprus",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "area_code": "CY",
            "langs": [
                {
                    "code": "el",
                    "translation": "Greek"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KDK",
            "area_code": "DK",
            "units": "metric",
            "name": "Denmark",
            "translation": "Denmark",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "da",
                    "translation": "Danish"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEA",
            "area_code": "AU",
            "units": "metric",
            "name": "Australia",
            "translation": "Australia",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEANZ",
            "area_code": "NZ",
            "units": "metric",
            "name": "New Zealand",
            "translation": "New Zealand",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEF",
            "area_code": "FI",
            "units": "metric",
            "name": "Finland",
            "translation": "Finland",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "fi",
                    "translation": "Finnish"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEH",
            "area_code": "HK",
            "units": "metric",
            "name": "Hong Kong",
            "translation": "Hong Kong",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "zh",
                    "translation": "Chinese"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEI",
            "area_code": "IN",
            "units": "metric",
            "name": "India",
            "translation": "India",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEM",
            "area_code": "MY",
            "units": "metric",
            "name": "Malaysia",
            "translation": "Malaysia",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KEN",
            "area_code": "KE",
            "units": "metric",
            "name": "Kenya",
            "translation": "Kenya",
            "dateformat": "dd/mm/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KES",
            "area_code": "SG",
            "units": "metric",
            "name": "Singapore",
            "translation": "Singapore",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KET",
            "area_code": "TW",
            "units": "metric",
            "name": "Taiwan",
            "translation": "Taiwan",
            "dateformat": "yyyy-MM-dd",
            "non_sap": "true",
            "langs": [
                {
                    "code": "zh",
                    "translation": "Chinese"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KHE",
            "area_code": "GR",
            "non_sap": "true",
            "units": "metric",
            "name": "Greece",
            "translation": "Greece",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "el",
                    "translation": "Greek"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KHN",
            "area_code": "NO",
            "units": "metric",
            "name": "Norway",
            "translation": "Norway",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                },
                {
                    "code": "no",
                    "translation": "Norwegian"
                }
            ]
        },
        {
            "code": "KHS",
            "area_code": "SE",
            "units": "metric",
            "name": "Sweden",
            "translation": "Sweden",
            "dateformat": "yyyy-MM-dd",
            "langs": [
                {
                    "code": "sv",
                    "translation": "Swedish"
                },
                {
                    "code": "no",
                    "translation": "Norwegian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KIE",
            "area_code": "ID",
            "units": "metric",
            "name": "Indonesia",
            "translation": "Indonesia",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "id",
                    "translation": "Indonesian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KIR",
            "area_code": "IE",
            "units": "metric",
            "name": "Ireland",
            "translation": "Ireland",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KIRGB",
            "area_code": "GB",
            "units": "metric",
            "name": "Ireland (UK of GB and Northern Ireland)",
            "translation": "Ireland (UK of GB and Northern Ireland)",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KIT",
            "area_code": "IT",
            "units": "metric",
            "name": "Italy",
            "translation": "Italy",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "it",
                    "translation": "Italian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KKT",
            "area_code": "TR",
            "units": "metric",
            "name": "Türkiye",
            "translation": "Türkiye",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "tr",
                    "translation": "Turkish"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KNL",
            "area_code": "NL",
            "units": "metric",
            "name": "Netherlands",
            "translation": "Netherlands",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "nl",
                    "translation": "Dutch"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KOB",
            "area_code": "BE",
            "units": "metric",
            "name": "Belgium",
            "translation": "Belgium",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "fr",
                    "translation": "French"
                },
                {
                    "code": "nl",
                    "translation": "Dutch"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KOE",
            "area_code": "EE",
            "units": "metric",
            "name": "Estonia",
            "translation": "Estonia",
            "dateformat": "dd.MM.yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "et",
                    "translation": "Estonian"
                },
                {
                    "code": "ru",
                    "translation": "Russian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KOF",
            "area_code": "FR",
            "units": "metric",
            "name": "France",
            "translation": "France",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "fr",
                    "translation": "French"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KOP",
            "area_code": "PT",
            "units": "metric",
            "name": "Portugal",
            "translation": "Portugal",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "pt",
                    "translation": "Portuguese"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KPI",
            "area_code": "PH",
            "units": "metric",
            "name": "Philippines",
            "translation": "Philippines",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KQA",
            "area_code": "QA",
            "units": "metric",
            "name": "Qatar",
            "translation": "Qatar",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KRO",
            "area_code": "RO",
            "units": "metric",
            "name": "Romania",
            "translation": "Romania",
            "dateformat": "dd.MM.yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "ro",
                    "translation": "Romanian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KSM",
            "area_code": "MX",
            "units": "metric",
            "name": "Mexico",
            "translation": "Mexico",
            "dateformat": "dd/MM/yyyy",
            "non_sap": "true",
            "langs": [
                {
                    "code": "es",
                    "translation": "Spanish"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KSP",
            "area_code": "ES",
            "units": "metric",
            "name": "Spain",
            "translation": "Spain",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "es",
                    "translation": "Spanish"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KSW",
            "area_code": "CH",
            "units": "metric",
            "name": "Switzerland",
            "translation": "Switzerland",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "fr",
                    "translation": "French"
                },
                {
                    "code": "de",
                    "translation": "German"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KUG",
            "area_code": "UG",
            "units": "metric",
            "name": "Uganda",
            "translation": "Uganda",
            "dateformat": "dd/mm/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KZA",
            "area_code": "ZA",
            "units": "metric",
            "name": "South Africa",
            "translation": "South Africa",
            "dateformat": "yyyy/MM/dd",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "LCZ",
            "area_code": "CZ",
            "units": "metric",
            "name": "Czech Republic",
            "translation": "Czech Republic",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "cs",
                    "translation": "Czech"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "LKE",
            "area_code": "HU",
            "units": "metric",
            "name": "Hungary",
            "translation": "Hungary",
            "dateformat": "yyyy.MM.dd",
            "non_sap": "true",
            "langs": [
                {
                    "code": "hu",
                    "translation": "Hungarian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "LPL",
            "area_code": "PL",
            "units": "metric",
            "name": "Poland",
            "translation": "Poland",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "pl",
                    "translation": "Polish"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "LRM",
            "area_code": "RU",
            "units": "metric",
            "name": "Russia",
            "translation": "Russia",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "ru",
                    "translation": "Russian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "LSK",
            "area_code": "SK",
            "units": "metric",
            "name": "Slovakia",
            "translation": "Slovakia",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "sk",
                    "translation": "Slovene"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "MAR",
            "area_code": "GB",
            "units": "metric",
            "name": "Marine",
            "translation": "Marine",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "OMA",
            "area_code": "OM",
            "units": "metric",
            "name": "Oman",
            "translation": "Oman",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "SAG",
            "area_code": "AT",
            "units": "metric",
            "name": "Austria",
            "translation": "Austria",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "de",
                    "translation": "German"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "SIG",
            "area_code": "SI",
            "units": "metric",
            "name": "Slovenia",
            "translation": "Slovenia",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "sl",
                    "translation": "Slovenian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "THA",
            "area_code": "TH",
            "units": "metric",
            "name": "Thailand",
            "translation": "Thailand",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "th",
                    "translation": "Thai"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "USK",
            "area_code": "US",
            "units": "imperial",
            "name": "United States",
            "translation": "United States",
            "dateformat": "MM/dd/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "ISR",
            "area_code": "IL",
            "units": "metric",
            "name": "Israel",
            "translation": "Israel",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                },
                {
                    "code": "he",
                    "translation": "Hebrew"
                }
            ]
        },
        {
            "code": "DZA",
            "area_code": "DZ",
            "units": "metric",
            "name": "Algeria",
            "translation": "Algeria",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "fr",
                    "translation": "French"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KVI",
            "area_code": "VN",
            "units": "metric",
            "name": "Vietnam",
            "translation": "Vietnam",
            "dateformat": "dd.MM.yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                },
                {
                    "code": "vi",
                    "translation": "Vietnamese"
                }
            ]
        },
        {
            "code": "KBG",
            "units": "metric",
            "name": "Bulgaria",
            "translation": "Bulgaria",
            "dateformat": "dd/MM/yyyy",
            "area_code": "BG",
            "langs": [
                {
                    "code": "bg",
                    "translation": "Bulgarian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "KCR",
            "units": "metric",
            "name": "Croatia",
            "translation": "Croatia",
            "dateformat": "dd/MM/yyyy",
            "area_code": "HR",
            "langs": [
                {
                    "code": "hr",
                    "translation": "Croatian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        },
        {
            "code": "CSE",
            "area_code": "US",
            "units": "metric",
            "name": "CSE",
            "translation": "CSE",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "en",
                    "translation": "English"
                }
            ],
            "hideForEnvironments": ["PROD"]
        },
        {
            "code": "KUA",
            "area_code": "UA",
            "units": "metric",
            "name": "Ukraine",
            "translation": "Ukraine",
            "dateformat": "dd/MM/yyyy",
            "langs": [
                {
                    "code": "ru",
                    "translation": "Russian"
                },
                {
                    "code": "uk",
                    "translation": "Ukrainian"
                },
                {
                    "code": "en",
                    "translation": "English"
                }
            ]
        }
    ]
}
